import * as fuseConsts from './constants';
import * as fuseUtils from './utils';
import jwtDecode from "jwt-decode";
import axios from "axios"

export const fuseLogin = async (username, password) => {
    return new Promise(async (resolve, reject) => {

        let url = fuseUtils.getUrl(fuseConsts.AUTH_LINKS.login)
        console.log(url, username, password)
        // CheckAuth
        try {
            let me = await checkFuseAuth()
            if(me){
                return resolve(me)
            }
        } catch (error) {
            
        }
        try {
            let response = await axios.post(url, {username, password});
            if(response.data && response.data.token && response.data.refresh_token){
                console.log("new token")
                console.log("new token1")
                let newTokens = formatTokens(response.data)
                console.log("new token 1")
                fuseSetToken(newTokens)
                console.log("new token 2")
                let user = await getMe(newTokens.token)
                console.log("new token 3")
                return resolve(user)
            }
        } catch (error) {
            console.log("error check tokens", error)
            return reject(null)
        }

/*
        axios.post(url, {username, password})
        .then(response => {
            console.log(response);
            if(response.data && response.data.token && response.data.refresh_token){
                console.log("new token")
                let newTokens = formatTokens(response.data)
                fuseSetToken(newTokens)
                getMe(newTokens.token).then(user => {
                    return resolve(user)
                    
                }).catch(reject(null))
            }
            reject(false)
        })
        .catch(error => {
            reject(error);
        });*/
    })
    
}

export const checkFuseAuth = async () => {
    return new Promise(async (resolve, reject) => {
        let me = null;
        try {
            let tokens = await checkTokens();
            if(tokens && tokens.token){
                me = await getMe(tokens.token)
                console.log(me)
            }
            return resolve(me)
        } catch (error) {
            return reject(error)
        }
    })
}

export const getMe = async (token) => {
    return new Promise((resolve, reject) => {
        let url = fuseUtils.getUrl(fuseConsts.AUTH_LINKS.me);
        axios
            .get(url, {
                headers: {
                    Authorization: "Bearer " + token
                }
            })
            .then(response => {
                if (response.data) {
                    let user = response.data;
                    //this.setSession(response.data.access_token);
                    let userFormatted = formatUser(user);
                    resolve(userFormatted);
                } else {
                    //this.logout();
                    reject("Failed to login with token.");
                }
            })
            .catch(error => {
                //this.logout();
                reject("Failed to login with token.");
            });
    });
}

export const formatUser = (user) => {
    return user
}


export const fuseSetToken = (tokens) => {
    localStorage.setItem(process.env.REACT_APP_DATA_TOKEN_ID, JSON.stringify(tokens));
}

export const fuseGetTokens = () => {
    let tokens = window.localStorage.getItem(process.env.REACT_APP_DATA_TOKEN_ID);
    tokens = JSON.parse(tokens);
    return tokens ? {token: tokens.token, refresh_token : tokens.refresh_token} : null;
}

export const fuseLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_DATA_TOKEN_ID);
}

export const fuseMe = () => {

}

export const checkTokens = async () => {
    return new Promise( async (resolve, reject) => {
        let tokens = fuseGetTokens()
        //console.log(tokens)
        if(!tokens || !tokens.refresh_token || !tokens.token){
            return resolve(null)
        }
        let isValid = isAuthTokenValid(tokens)
        //console.log("isValid", isValid)

        if(isValid){
            //console.log("token is valid")
            return resolve(tokens)
        }
        try {
            let newTokens = await renewToken()
            //console.log("newTokens", newTokens)
            return resolve(newTokens)
        } catch (error) {
            return resolve(null)
        }
    })
}

export const isAuthTokenValid = (tokens) => {
    if (!tokens.token || !tokens.refresh_token) {
        return false;
    }
    const decoded = jwtDecode(tokens.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn("access token expired");
        return false;
    } else {
        return true;
    }
};

export const fuseGetAccessToken = () => {
    let tokens = fuseGetTokens()
    return tokens?.token
}

export const fuseGetRefreshToken = () => {
    let tokens = fuseGetTokens()
    return tokens?.refresh_token
}

export const renewToken = async () => {
    return new Promise((resolve, reject) => {
        let refreshToken = fuseGetRefreshToken()
        console.log(refreshToken);
        if (!refreshToken) {
            return reject(refreshToken);
        }
        let url = fuseUtils.getUrl(fuseConsts.AUTH_LINKS.tokenRefresh);
        console.log("renewToken");
        console.log(url);
        axios
            .post(url, { refresh_token: refreshToken })
            .then(response => {
                if (
                    !response.data ||
                    !response.data.token ||
                    !response.data.refresh_token
                ) {
                    return reject(null);
                }

                let newTokens = formatTokens(response.data)
                console.log("newTokens", newTokens);
                fuseSetToken(newTokens);
                resolve(newTokens);
            })
            .catch(error => reject(error));
    });
}

const formatTokens = (data) => {
    return {token : data.token, refresh_token: data.refresh_token}
}